// extracted by mini-css-extract-plugin
export var header = "contact-module--header--nQccw";
export var form = "contact-module--form--tFRjR";
export var social = "contact-module--social--lAjN0";
export var componentContainer = "contact-module--componentContainer--jnTpI";
export var contactContainer = "contact-module--contactContainer--6sgk5";
export var contact = "contact-module--contact--MoOIw";
export var floatingLabel = "contact-module--floatingLabel--jmynp";
export var socialLinks = "contact-module--socialLinks--2QKnb";
export var linkedin = "contact-module--linkedin--EQLND";
export var github = "contact-module--github--jFqTm";
export var stackOverflow = "contact-module--stackOverflow--zFXAB";
export var sending = "contact-module--sending--NtWwt";