// extracted by mini-css-extract-plugin
export var imageCol = "projects-module--imageCol--0Dh56";
export var projectsWrapper = "projects-module--projectsWrapper--Mtmmn";
export var componentContainer = "projects-module--componentContainer--D2ZO8";
export var filterContainer = "projects-module--filterContainer--KfZkS";
export var filters = "projects-module--filters--3VnHC";
export var filter = "projects-module--filter--tqQ3+";
export var activeFilter = "projects-module--activeFilter--cLh-h";
export var projects = "projects-module--projects--E61pi";
export var imageContainer = "projects-module--imageContainer--sYnFZ";
export var projectImg = "projects-module--projectImg--Isf9P";
export var overlay = "projects-module--overlay--aWO16";
export var projectTitle = "projects-module--projectTitle--zWtVK";
export var skill = "projects-module--skill--TB2pI";
export var projectTech = "projects-module--projectTech--WLNTV";
export var angleRight = "projects-module--angleRight--43iC0";